$grid-breakpoints: (
        xxxxs: 0,
        xxxs: 340,
        xxs: 400,
        xs: 500px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);
$blue: #216dde;
$green: #0f5a37;
$yellow: #906d00;

$form-label-font-size: .95rem;
$input-btn-padding-x: .65rem;
$card-border-width: 0;
